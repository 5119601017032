import { useState } from "react";
import { SaveIcon } from "allIcons";
import {
  BodyContainer,
  HeaderContainer,
  InputField,
  Loader,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  SecondaryHeading,
  ToggleCard,
} from "commonUI";
import { isEqual, useSearch, parse, omit } from "helpers";
import { useSites, useUpdateSite } from "api/sites";
import { useEffect } from "react";
import { LoadingCard } from "commonUI";
import {
  useGeneralSettings,
  useUpdateGeneralSettings,
} from "hooks/generalSetting";
import { t } from "i18next";

export default function GeneralSettings() {
  const [inputValue, setInputValue] = useState({
    status: 0,
    datepicker: true,
    time_picker_minutes_interval: 60,
    isLoading: true,
  });
  const { web = null } = useSearch();
  const { data, isLoading, error } = useSites();
  const [siteData, setSiteData] = useState({});
  const { data: siteGeneralSettings, isLoading: loadingSiteGeneralSettings } =
    useGeneralSettings();

  const { mutate: updateGeneralSettings, isLoading: isUpdatingGeneralSetting } =
    useUpdateGeneralSettings();

  useEffect(() => {
    if (loadingSiteGeneralSettings) return;
    const temp = siteGeneralSettings.find((item) =>
      isEqual(item.website_id, web ?? null)
    );
    if (temp) {
      setInputValue({
        ...inputValue,
        ...parse(temp.general_settings),
        isLoading: false,
      });
    }
  }, [loadingSiteGeneralSettings, siteGeneralSettings, web]);

  useEffect(() => {
    if (isLoading) return;
    setSiteData(
      data?.site_details?.total_sites.filter((item) =>
        isEqual(item.dl_website_id, web)
      )[0]
    );
  }, [isLoading, web]);
  const { mutate: updateSite, isLoading: isUpdating } = useUpdateSite(false);

  const handleSave = () => {
    if (web) {
      updateSite({
        website_key: siteData.dl_website_id,
        status: siteData?.status || true,
      });
    }
    let temp = siteGeneralSettings?.find((item) =>
      isEqual(item.website_id, web || null)
    );
    updateGeneralSettings({
      ...temp,
      website_id: web || null,
      general_settings: omit(["isLoading"], inputValue),
    });
  };
  return (
    <>
      <RightMainContainer>
        <Loader show={isUpdatingGeneralSetting || isUpdating} loader="block" />
        <HeaderContainer>
          <PrimaryHeading>
            {t("siteSetting.generalSettings.title")}
          </PrimaryHeading>
          <MainButton onClick={handleSave}>
            <SaveIcon />
            {t("common.button.saveChanges")}
          </MainButton>
        </HeaderContainer>
        <BodyContainer>
          {web ? (
            <>
              <PrimaryHeading className="text-17">{t("siteSetting.generalSettings.siteDetails.title")}</PrimaryHeading>
              <SecondaryHeading className="mt-2">
                {t("siteSetting.generalSettings.siteDetails.connectedWordpressSiteURL")}
              </SecondaryHeading>
              {isLoading ? (
                <LoadingCard className="h-2 w-40 mt-2" />
              ) : (
                <p className="body-text mt-1 mb-3">{siteData?.web_url}</p>
              )}

              <SecondaryHeading className="mt-2">{t("siteSetting.generalSettings.siteDetails.diskUsage")}</SecondaryHeading>
              {isLoading ? (
                <LoadingCard className="h-2 w-40 mt-2" />
              ) : (
                <p className="body-text mt-1">
                  {siteData?.single_web_storage} GB
                </p>
              )}
              <SecondaryHeading className="mt-2">
                {t("siteSetting.generalSettings.siteDetails.enableWPSiteLauncher.title")}
              </SecondaryHeading>
              {isLoading ? (
                <>
                  <LoadingCard className="h-2 w-full mt-2" />
                  <LoadingCard className="h-2 w-1/2 mt-2" />
                </>
              ) : (
                <p className="body-text mt-1">
                  {t("siteSetting.generalSettings.siteDetails.enableWPSiteLauncher.description")}
                </p>
              )}
              <ToggleCard
                isLoading={inputValue.isLoading}
                value={
                  inputValue?.status !== null
                    ? isEqual(inputValue?.status, 1)
                    : true
                }
                setValue={(value) =>
                  setInputValue({
                    ...inputValue,
                    status: value === true ? "1" : "0",
                  })
                }
              />
            </>
          ) : (
            ""
          )}
          {/* <div className="">
            <PrimaryHeading className="text-17">Date & Time</PrimaryHeading>
            <SecondaryHeading className="mt-2">
              Disable Time In Datepicker
            </SecondaryHeading>
            <p className="body-text text-theme-placeholder mt-1">
              Choose to enable or disable the time feature. If disabled, the
              datepicker will only use dates and not specific times.
            </p>
            <ToggleCard
              value={inputValue.datepicker}
              setValue={(value) =>
                setInputValue({
                  ...inputValue,
                  datepicker: value,
                })
              }
            />
          </div>
          <div className="mt-3">
            <InputField
              label="Time Picker Minutes Interval"
              className="gap-0"
              type="number"
              value={inputValue.time_picker_minutes_interval}
              setValue={(value) =>
                setInputValue({
                  ...inputValue,
                  time_picker_minutes_interval: value,
                })
              }
              placeholder="Write Time Picker Minutes Interval Here"
            />
          </div> */}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}

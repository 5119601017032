import { useEffect, useState } from "react";
import { InputField, MainButton, Loader } from "commonUI";
import "./auth.css";
import { Logo } from "allIcons";
import { ApiResponseType } from "enums";
import { axiosInstance, useSearch, useUtilities } from "helpers";
import { useUserAuth } from "context/UserAuth";
import { useUserLogout } from "hooks/user";

export default function ForgotPassword() {
  const {
    userAuth: { token },
  } = useUserAuth();
  const {navigate} = useUtilities();
  const { from } = useSearch();
  const [timer, setTimer] = useState(0);
  const [userInput, setUserInput] = useState({
    email: "",
    error: "",
    sucess: "",
  });
  const [loading, setLoading] = useState(false);
  const {mutate: logoutUser, isLoading} = useUserLogout(false);

  useEffect(() => {
    if (!token) return;
    if (from === 'website') {
      logoutUser()
    } else {
      navigate('/')
    }
  }, [from, token])

  useEffect(() => {
    let timerInterval = setInterval(() => {
      setTimer(timer - 1);
    }, 1000);
    if (timer === 0) {
      clearInterval(timerInterval);
    }
    return () => clearInterval(timerInterval);
  }, [timer]);

  function handleForget(e) {
    e.preventDefault();
    if (!userInput.email) {
      setUserInput({
        ...userInput,
        error: "Email is required",
        sucess: "",
      });
      return;
    }
    setLoading(true);
    axiosInstance
      .post(`forgetpassword`, {
        email: userInput.email,
      })
      .then((res) => res.data)
      .then((data) => {
        if (data.StatusCode === ApiResponseType.SUCCESS) {
          setUserInput({
            ...userInput,
            sucess: data.Message,
            error: "",
          });
          setTimer(59);
        } else {
          setUserInput({
            ...userInput,
            error: "Invalid email",
            sucess: "",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setUserInput({
          ...userInput,
          error: "Invalid email",
          sucess: "",
        });
        setLoading(false);
      });
  }
  return (
    <>
    <Loader show={loading || isLoading} />
      <div className={`login-container`}>
        <div className="">
          <div
            className={`flex items-center bg-transparent mb-12 justify-center`}
          >
            <Logo className="logo-size" />
          </div>
          <div className="login-form">
            <h2 className="login-heading"> Forgot Password</h2>
            <p className="body-text forget-details">
              Enter the email address associated with your account and we'll
              send you a link to reset your password.
            </p>
            <div className="py-4 w-fit mx-auto">
              {userInput.error ? (
                <p className="error-text mb-1">{userInput.error}</p>
              ) : (
                userInput.sucess && (
                  <p className="success-text mb-1">{userInput.sucess}</p>
                )
              )}
              <InputField
                type="text"
                name="email"
                id="email_address"
                placeholder="Write Email Here"
                value={userInput.email}
                setValue={(value) =>
                  setUserInput({ ...userInput, email: value })
                }
                inputClass="py-1"
              />
              <div className="py-2">
                <MainButton onClick={handleForget} className={`w-full py-2 `}>
                  Send
                </MainButton>
              </div>
              <div className="">
                <p className="signup-link">
                  Didn't receive an email? &nbsp;
                  <span
                    className={`forget-link cursor-pointer ${
                      timer ? "disabled" : ""
                    }`}
                    onClick={handleForget}
                  >
                    Resend {timer ? `in ${timer} seconds` : ""}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { MainButton, LimitReachedCard, ModuleLimitDetail } from "commonUI";
import { OpenLinkIcon } from "allIcons";
import "./Sites.css";
import { useSites } from "api/sites";
import { isEqual } from "helpers";
import { t } from "i18next";

export default function SiteCard(props) {
  const { data = {} } = useSites();
  const { storage_details, site_details } = data;
  return (
    <>
      <div className="site-card">
        <LimitReachedCard
          show={isEqual(
            site_details?.sites_limit,
            site_details?.total_used_site
          )}
          title={t("sites.siteUsageDetail.limitReached.title")}
          description={t("sites.siteUsageDetail.limitReached.body")}
        />
        <ModuleLimitDetail
          title={t("sites.siteUsageDetail.title")}
          isLoading={props.isLoading}
          description={props.sites.description}
          progress={props.sites.line}
        />
        <LimitReachedCard
          show={isEqual(
            storage_details?.storage_limit,
            storage_details?.total_used_storage
          )}
          title={t("sites.storageUsageDetail.limitReached.title")}
          description={t("sites.storageUsageDetail.limitReached.body")}
        />
        <ModuleLimitDetail
          title={t("sites.storageUsageDetail.title")}
          isLoading={props.isLoading}
          description={props.storage.description}
          progress={props.storage.line}
        />
        <a
          href="https://wpsitelauncher.com/customer-dashboard/?action=index&model=subscription"
          target="_blank"
          rel="noreferrer"
        >
          <MainButton>
            <OpenLinkIcon />
            {t("common.button.managePlan")}
          </MainButton>
        </a>
      </div>
    </>
  );
}

import { useState } from "react";
import { InputField, MainButton, Loader } from "commonUI";
import "./auth.css";
import { useNavigate } from "react-router-dom";
import { Logo } from "allIcons";
import { printError, useSearch, useUtilities } from "helpers";
import { ApiResponseType } from "enums";
import axios from "axios";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState({
    password: "",
    confirm_password: "",
    error: "",
    sucess: "",
  });
  const { rt, email } = useSearch();
  const [loading, setLoading] = useState(false);
  const { notification } = useUtilities();

  function handleResetPassword(e) {
    e.preventDefault();
    if (userInput.password.length < 8) {
      setUserInput({
        ...userInput,
        error: "Password must be at least 8 characters",
        sucess: "",
      });
      setLoading(false);
      return;
    }
    if (userInput.password.length > 30) {
      setUserInput({
        ...userInput,
        error: "Your password cannot be longer than 30 characters.",
        sucess: "",
      });
      setLoading(false);
      return;
    }
    if (userInput.password !== userInput.confirm_password) {
      setUserInput({
        ...userInput,
        error:
          "The passwords you entered do not match. Please check and try again",
        sucess: "",
      });
      return;
    }
    if (
      !userInput.password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,40}$/
      )
    ) {
      setUserInput({
        ...userInput,
        error:
          "Your password must include at least one uppercase letter, one lowercase letter, one number, and one special character (like @, $, %, *, ?, &).",
        sucess: "",
      });
      setLoading(false);
      return;
    }
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}resetpassword`, {
        email,
        password: userInput.password,
        rt,
      })
      .then((res) => res.data)
      .then((data) => {
        if (data.StatusCode === ApiResponseType.SUCCESS) {
          setUserInput({
            ...userInput,
            sucess: data.Message,
            error: "",
          });
          notification({
            message: "Password updated successfully",
            type: "success",
          });
          navigate("/login");
        } else {
          setUserInput({
            ...userInput,
            error: printError(data.Message),
            sucess: "",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setUserInput({
          ...userInput,
          error: "Invalid email",
          sucess: "",
        });
        setLoading(false);
      });
  }
  return (
    <>
      <Loader show={loading} />
      <div className={`login-container`}>
        <div className="">
          <div
            className={`flex items-center bg-transparent mb-12 justify-center`}
          >
            <Logo className="logo-size" />
          </div>
          <div className="login-form">
            <h2 className="login-heading">Reset Password</h2>
            <div className="py-4 w-fit mx-auto max-w-25rem">
              {userInput.error ? (
                <p className="error-text mb-1">{userInput.error}</p>
              ) : (
                userInput.sucess && (
                  <p className="success-text mb-1">{userInput.sucess}</p>
                )
              )}
              <InputField
                type="text"
                name="email"
                id="email_address"
                placeholder="Write Email Here"
                value={email}
                inputClass="py-1"
                disabled
              />
              <InputField
                type="password"
                name="password"
                id="password"
                placeholder="Write Password Here"
                value={userInput.password}
                inputClass="py-1"
                setValue={(value) =>
                  setUserInput({ ...userInput, password: value })
                }
              />
              <InputField
                type="password"
                name="confirm_password"
                id="confirm_password"
                placeholder="Write Confirm Password Here"
                value={userInput.confirm_password}
                inputClass="py-1"
                setValue={(value) =>
                  setUserInput({
                    ...userInput,
                    confirm_password: value,
                  })
                }
              />
              <div className="py-2">
                <MainButton
                  onClick={handleResetPassword}
                  className={`w-full py-2 `}
                >
                  Update Password
                </MainButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

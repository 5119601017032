import { LoadingCard, SecondaryHeading, OutsideClickHandler } from "commonUI";
import { useState } from "react";
import { CheckIcon, FormFieldIcon } from "allIcons";
import { isEqual } from "helpers";

export default function SelectField({
  disabled = false,
  icon = "",
  description = "",
  style = {},
  fieldLabel,
  secondaryLabel = "",
  ...props
}) {
  const [open, setOpen] = useState(false);

  const getLabel = () => {
    if (Array.isArray(props?.children)) {
      const selectedChild = props?.children?.filter((child) => {
        if (isEqual(child?.props?.value, props?.value)) {
          return child?.props?.children;
        }
      })[0]?.props?.children;
      return (selectedChild || fieldLabel) ?? props.placeholder ?? "Select";
    }
    return fieldLabel ?? props.placeholder ?? "Select";
  };

  return (
    <>
      <div
        className={`flex flex-col gap-7 mb-2 ${props.className ?? ""}`}
        style={style}
      >
        {props.label && <h3 className="secondary-heading">{props.label}</h3>}
        {description ? (
          <p className="body-text text-theme-placeholder text-11 m-0">
            {description}
          </p>
        ) : (
          ""
        )}
        <div className="flex w-full">
          {props.isLoading ? (
            <LoadingCard className="h-6" />
          ) : (
            <div
              className="select-field"
              onClick={() => {
                if (disabled) return;
                setOpen((pre) => !pre);
              }}
            >
              <div
                className={`flex items-center justify-between w-full relative ${
                  disabled ? "disabled" : ""
                }`}
              >
                <div className="flex items-center gap-10">
                  {icon}
                  <SecondaryHeading
                    className={`text-13 ${
                      !props.value ? "text-theme-secondary" : ""
                    }`}
                  >
                    {getLabel()}
                  </SecondaryHeading>
                </div>
                <div className="flex items-center">
                  {secondaryLabel ? (
                    <p className="text-11 text-theme-placeholder mr-2">
                      {secondaryLabel}
                    </p>
                  ) : (
                    ""
                  )}
                  <FormFieldIcon fill="#4C5866" />
                </div>
              </div>
              {
                <OutsideClickHandler
                  onClickOutside={() => {
                    setOpen(false);
                  }}
                  className={`transition-all ${
                    open ? "select-dropdown" : "hide-dropdown"
                  }`}
                >
                  <div>
                    {props?.children?.length > 0 ? (
                      props.children.map((child) => (
                        <div
                          className={`select-dropdown-item ${child.props.className}`}
                          onClick={() => {
                            props.setValue(child.props.value);
                            setOpen(false);
                          }}
                        >
                          {child.props.children}
                          <div className="flex items-center gap-8">
                            {child.props.secondaryLabel && (
                              <p className="text-11 text-theme-placeholder">
                                {child.props.secondaryLabel}
                              </p>
                            )}
                            {isEqual(child.props.value, props.value) && (
                              <CheckIcon fill="#64C1AB" />
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="select-dropdown-item w-full">No Data</div>
                    )}
                  </div>
                </OutsideClickHandler>
              }
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export const pageTitles = {
  sites: {
    title: "sites.title",
    explainerText: "sites.explainerText",
  },
  "template-library": {
    title: "templateLibrary.title",
    explainerText: "templateLibrary.explainerText",
  },
  users: {
    title: "users.title",
    explainerText: "users.explainerText",
  },
  "site-settings": {
    title: "siteTemplate.title",
    explainerText:
      "Manage the users, settings, user preferences, and saved templates that will be configured by default for all new sites where the WP SiteLauncher is installed.",
  },
  "": {
    title: "sites.title",
    explainerText: "sites.explainerText",
  },
};

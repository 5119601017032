import Header from "./Header";
import SiteTab from "sites";
import { Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import GeneralMenu from "leftMenu/GeneralMenu";
import LibraryMenu from "leftMenu/LibraryMenu";
import FeatureMenu from "leftMenu/FeatureMenu";
import {
  PrimaryHeading,
  InvalidURL,
  MainButton,
  LimitReachedCard,
} from "commonUI";
import UserModule from "usersModule/index";
import UserMenu from "leftMenu/UserMenu";
import Library from "library";
import SiteSettingRoutes from "siteSettings";
import SecondaryMenu from "siteSettings/Menu";
import { useUserMenu } from "context/UserMenu";
import { BackArrow, RedirectIcon } from "allIcons";
import { isEqual, useSearch, useUtilities, usePathname } from "helpers";
import { useSites } from "api/sites";
import UserPrefrencesMenu from "leftMenu/UserPrefrencesMenu";
import { SiteSettingsMenu, pageTitles, ModulePrefixes } from "enums";
import useUserPlan from "hooks/plan";
import { t } from "i18next";

export default function HomeScreen() {
  const { navigate } = useUtilities();
  const { userMenu, setUserMenu } = useUserMenu();
  const [activeMenu, setActiveMenu] = useState(
    userMenu ?? SiteSettingsMenu.GENERAL_SETTINGS
  );
  const { data: wpslPlan, isLoading: loadingPlanDetails } = useUserPlan();
  const [pathName] = usePathname();
  const { web } = useSearch();
  const { data, isLoading, error } = useSites();
  const siteDetails = data?.site_details?.total_sites?.find((site) =>
    isEqual(site.dl_website_id, web)
  );
  const isLibraryTemplateAllowed = isEqual(wpslPlan?.temp_access, 1);

  useEffect(() => {
    if (pathName === ModulePrefixes.SITES || pathName === "") {
      setActiveMenu(SiteSettingsMenu.GENERAL_SETTINGS);
      setUserMenu(SiteSettingsMenu.GENERAL_SETTINGS);
    }
  }, [pathName]);

  const renderSubMenu = () => {
    if (pathName === "") return;
    if (pathName === ModulePrefixes.SITES) return;
    if (pathName === ModulePrefixes.TEMPLATE_LIBRARY) return;
    if (pathName === ModulePrefixes.USERS) return;

    return (
      <SecondaryMenu
        activeMenu={activeMenu}
        setActiveMenu={(val) => {
          setActiveMenu(val);
          setUserMenu(val);
        }}
        isLibraryTemplateAllowed={isLibraryTemplateAllowed}
      />
    );
  };

  const renderLeftMenu = () => {
    if (pathName === ModulePrefixes.SITES || pathName === "") return;
    if (pathName === ModulePrefixes.TEMPLATE_LIBRARY)
      return (
        <div className="left-side-container">
          {isLibraryTemplateAllowed ? (
            <LibraryMenu />
          ) : (
            <LimitReachedCard
              show
              title="Template Library Not Included"
              description="Please note that access to the Template Library is not included in your current plan. To utilize this feature, consider upgrading your account"
            />
          )}
        </div>
      );
    if (pathName === ModulePrefixes.USERS)
      return (
        <div className="left-side-container relative">
          <UserMenu />
        </div>
      );
    if (pathName === ModulePrefixes.SITE_SETTINGS) {
      if (activeMenu === SiteSettingsMenu.GENERAL_SETTINGS) {
        return (
          <div className="left-side-container">
            <GeneralMenu />
          </div>
        );
      }
      if (activeMenu === SiteSettingsMenu.FEATURES_SETTINGS) {
        return (
          <div className="left-side-container">
            <FeatureMenu />
          </div>
        );
      }
      if (activeMenu === SiteSettingsMenu.USER_PREFRENCES) {
        return (
          <div className="left-side-container">
            {!isLibraryTemplateAllowed ? <></> : <UserPrefrencesMenu />}
          </div>
        );
      }
      if (activeMenu === SiteSettingsMenu.LIBRARY_TEMPLATES) {
        return (
          <div className="left-side-container">
            <LibraryMenu siteTemplate />
          </div>
        );
      }
    }
  };

  return (
    <>
      <div className={`home-screen-container `}>
        <Header />
        <div className="main-body-containter">
          <div className="main-body-wrapper box-shadow">
            <div className="main-body-header">
              <div className="flex items-center gap-10">
                {web ? (
                  <BackArrow fill="white" onClick={() => navigate("/sites")} />
                ) : (
                  ""
                )}
                <PrimaryHeading className="text-white">
                  {web
                    ? `${siteDetails?.web_name} ${t(
                        "siteTemplate.siteSetting"
                      )}`
                    : t(pageTitles[pathName]?.title) ?? t("siteTemplate.title")}
                  {web ? (
                    ""
                  ) : (
                    <p className="explainer-text">
                      {pathName === ModulePrefixes.TEMPLATE_LIBRARY &&
                      !isLibraryTemplateAllowed
                        ? ""
                        : t(pageTitles[pathName]?.explainerText) ??
                          t("siteTemplate.explainerText")}
                    </p>
                  )}
                </PrimaryHeading>
              </div>
              {web ? (
                <a
                  href={siteDetails?.web_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MainButton>
                    <RedirectIcon /> {t("common.button.goToWordpressSite")}
                  </MainButton>
                </a>
              ) : (
                ""
              )}
            </div>
            {renderSubMenu()}
            <div
              className="flex bg-theme-card"
              style={{
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
              }}
            >
              {renderLeftMenu()}
              <div
                className={`right-side-container ${
                  pathName === ModulePrefixes.SITES ||
                  pathName === "" ||
                  (pathName === ModulePrefixes.TEMPLATE_LIBRARY &&
                    !isLibraryTemplateAllowed &&
                    !loadingPlanDetails)
                    ? "bg-transparent"
                    : ""
                }`}
              >
                <Routes>
                  <Route exact path={`/`} element={<SiteTab />} />
                  {/* Sites UI routes */}
                  <Route exact path={`/${ModulePrefixes.SITES}`}>
                    <Route exact index element={<SiteTab />} />
                  </Route>

                  {/* Site Setting UI routes */}
                  <Route
                    exact
                    path={`/${ModulePrefixes.SITE_SETTINGS}/*`}
                    element={<SiteSettingRoutes />}
                  />
                  {/* Library UI routes */}
                  <Route
                    exact
                    path={`/${ModulePrefixes.TEMPLATE_LIBRARY}/*`}
                    element={isLibraryTemplateAllowed ? <Library /> : <></>}
                  />
                  {/* Users UI routes */}
                  <Route
                    exact
                    path={`/${ModulePrefixes.USERS}/*`}
                    element={<UserModule />}
                  />
                  <Route path={`*`} element={<InvalidURL />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
